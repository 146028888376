<script setup lang="ts">
import type {
  VoixCheckboxFieldInterface,
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

// Config
const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    showWaves: VoixCheckboxFieldInterface
    tagline: VoixTextFieldInterface
    left: VoixCheckboxFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    buttonOne: VoixLinkFieldInterface
    buttonTwo: VoixLinkFieldInterface
    buttonThree: VoixLinkFieldInterface
    hideFlower: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Wide Image Bg Small White Box',
    group: 'playaresorts',
    layouts: ['*'],
  },
  tags: ['Image'],
  fields: {
    image: {
      type: 'media',
      label: 'Background Image',
      breakpoints: {
        lg: { width: 1800, height: 800 },
        default: { width: 600, height: 600 },
      },
      name: 'image',
    },
    showWaves: {
      type: 'checkbox',
      label: 'Show Waves?',
      default: false,
      name: 'showWaves',
    },
    tagline: { type: 'text', label: 'Tagline', enabled: false, name: 'tagline' },
    left: { type: 'checkbox', label: 'Box on left', default: true, name: 'left' },
    title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
    copy: { type: 'wysiwyg', label: 'Copy', enabled: false, name: 'copy' },
    buttonOne: {
      type: 'link',
      label: 'Button One',
      enabled: false,
      name: 'buttonOne',
    },
    buttonTwo: {
      type: 'link',
      label: 'Button Two',
      enabled: false,
      name: 'buttonTwo',
    },
    buttonThree: {
      type: 'link',
      label: 'Button Three (Plain Text)',
      enabled: false,
      name: 'buttonThree',
    },
    hideFlower: {
      type: 'checkbox',
      label: 'Hide Flower (Jewels)?',
      default: false,
      name: 'hideFlower',
    },
  },
  slots: [],
})

// Composables
const { $voix } = useNuxtApp()
const { toggleBookingModal } = useBookingWidgetStore()

// Computed
const isMediumBreakpoint = computed(() => {
  return true
  // I don't think this is necessary anymore but leaving below JIC
  // if ($voix.breakpoint.isGreaterThan('sm'))
  //   return true
  // return false
})
const justify = computed(() => {
  return {
    'justify-left': props.fields.left.value,
    'justify-end': !props.fields.left.value,
  }
})

// Methods
function executeLink(e) {
  const href = e.target.href
  if (href.substr(href.length - 9) === '#book-now') {
    toggleBookingModal()
  }
  else {
    if (e.target.target === '_blank') {
      const win = window.open(href, '_blank')
      win.focus()
      return
    }
    window.location.href = href
  }
}
</script>

<template>
  <div class="wide-image-bg-small-white-box">
    <div v-if="isMediumBreakpoint">
      <VoixMedia v-slot="slotProps" :field="fields.image" :background="true">
        <div
          class="flex justify-center bg-grey bg-cover bg-center wide-image-bg-small-white-box"
          :style="{ backgroundImage: `url(${slotProps.image})` }"
        >
          <div class="p-2 md:p-15 md:py-16 container overflow-x-hidden">
            <div :class="justify" class="flex flex-col md:flex-row items-stretch">
              <div
                class="w-full md:w-1/3 mt-12 bg-white px-12 py-10 shadow-lg the-box font-sans"
                :class="{ 'no-flower': fields.hideFlower.value }"
              >
                <p v-if="fields.tagline.enabled" class="text-lg pb-1 wysiwyg">
                  {{ fields.tagline.value }}
                </p>
                <h4 class="text-3xl uppercase title font-normal">
                  {{ fields.title.value }}
                </h4>
                <div
                  v-if="fields.copy.enabled"
                  class="my-4 prose"
                  v-html="fields.copy.value"
                />
                <a
                  v-if="fields.buttonOne.enabled"
                  :href="fields.buttonOne.value?.href"
                  class="btn btn-ghost inline-block m-2 w-auto"
                  @click.prevent="executeLink"
                >
                  {{ fields.buttonOne.value?.text }}
                </a>
                <a
                  v-if="fields.buttonTwo.enabled"
                  :href="fields.buttonTwo.value?.href"
                  class="btn btn-ghost inline-block m-2"
                  @click.prevent="executeLink"
                >
                  {{ fields.buttonTwo.value?.text }}
                </a>

                <a
                  v-if="fields.buttonThree.enabled"
                  :href="fields.buttonThree.value?.href"
                  class="text-link"
                  @click.prevent="executeLink"
                >
                  {{ fields.buttonThree.value?.text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </VoixMedia>
    </div>

    <div
      v-else
      class="flex justify-center bg-grey-lightest bg-cover bg-center wide-image-bg-small-white-box"
    >
      <div class="p-2 md:p-15 md:py-16 container">
        <div :class="justify" class="flex flex-col md:flex-row items-stretch md:-mx-8">
          <div class="w-full md:hidden">
            <VoixMedia :field="fields.image" class="w-full h-full object-cover" alt="Dinning & Drinks" />
          </div>
          <div
            class="w-full md:w-1/3 bg-white px-8 py-10 text-center shadow-lg the-box"
            :class="{ 'no-flower': fields.hideFlower.value }"
          >
            <p v-if="fields.tagline.enabled" class="text-lg pb-1 wysiwyg">
              {{ fields.tagline.value }}
            </p>
            <div class="text-3xl uppercase title font-normal">
              {{ fields.title.value }}
            </div>
            <div
              v-if="fields.copy.enabled"
              class="my-4 prose"
              v-html="fields.copy.value"
            />
            <a
              v-if="fields.buttonOne.enabled"
              :href="fields.buttonOne.value?.href"
              class="btn btn-ghost inline-block m-2"
            >
              {{ fields.buttonOne.value?.text }}
            </a>
            <a
              v-if="fields.buttonTwo.enabled"
              :href="fields.buttonTwo.value?.href"
              class="btn btn-ghost inline-block m-2"
            >
              {{ fields.buttonTwo.value?.text }}
            </a>

            <a
              v-if="fields.buttonThree.enabled"
              :href="fields.buttonThree.value?.href"
              class="text-link"
            >
              {{ fields.buttonThree.value?.text }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="fields.showWaves.value"
      class="w-full relative hidden md:block"
      style="height: 0; margin-top: -100px; padding-bottom: 100px"
    >
      <svg
        width="100%"
        height="115"
        class="absolute left-0 top-0 z-10"
        viewBox="0 0 1287 77"
        preserveAspectRatio="none"
      >
        <path
          fill="#fff"
          d="M0,3c0,0,13,7,30,8c0,0,433,12,512,5c0,0,136-4,159-9c0,0,306,10,350-4l36-3c0,0,178,10,200,2v75H0V3z"
        />
      </svg>
    </div>
  </div>
</template>
